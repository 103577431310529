import React from 'react'
import { Styling } from './styled.js'
import {GatsbyImage,  getImage} from 'gatsby-plugin-image';


export default function BrandsGrid({brands, handleActiveBrand}) {
    return (
        <Styling>
              <div className="grid-wrapper">
                  {
                    brands.map((brand, index) => {
                        const theImage = getImage(brand?.brandsFields?.logo?.localFile);
                        return(
                            <div 
                            className="brand image-wrapper" key={`brand_${index}`}
                            data-title={brand.title}
                            onClick={(e) => handleActiveBrand(e)}
                            >
                                <GatsbyImage objectFit="contain" image={theImage} className="image" alt={'test'} />
                            </div>
                        )
                    })
                  }
              </div>
        </Styling>
    )
}
