import React, {useState} from 'react'
import { graphql } from 'gatsby'
import Hero from '../components/global/Hero'
import IntroSectionReuseable from '../components/reuseable/IntroSectionReuseable'
import BrandsGrid from '../components/brands/BrandsGrid'
import BrandsOffscreen from '../components/brands/BrandsOffscreen'
import CTASection from '../components/global/CTASection'
import toggleOffPageShow from '../utils/toggleOffPageShow'
import toggleOffPageHide from '../utils/toggleOffPageHide'
import Texture from '../components/animation/Texture'
import { Helmet } from 'react-helmet'

export default function OurBrands({data}) {
  const brands = data.brands.nodes;
  const [isActive, setIsActive] = useState(false);
  const [activeBrand, setActiveBrand] = useState(brands[0]);
  
  const handleActiveBrand = (e) => {
    let needle = e.target.closest('.brand').getAttribute('data-title');
    let activeBrand = Array.from(brands).filter(brand => {
      if(brand.title === needle){
        console.log('brand', brand)
        return brand
      }
    }) 
    // toggleOffPageHide('.brand', true)
    setActiveBrand(activeBrand[0])
    toggleOffPageShow({page:'brand'})
  }

  return (
    <div>
      <Helmet>
          <title>{data.wpPage.seo.title}</title>
          <meta name="description" content={data.wpPage.seo.metaDesc} />
          <meta name="og:description" content={data.wpPage.seo.metaDesc} />
          <meta property="og:title" content={data.wpPage.seo.title} />          
          <meta property="og:image" content={'https://morlandbathrooms.com'+data.wpPage.customHeroImage.heroImage.localFile.publicURL} />
      </Helmet>
      <Texture 
      className='texture full transform-offscreen-child' 
      opacity={0.05}  
      position='absolute'
      bottom="0"
      height={''}
      top="0"
      watchScroll={false}
      />
      <div className="offpage-translate offpage-translate-bg"></div>
        <div className="offpage-translate">
          <Hero image={data.wpPage.customHeroImage.heroImage} title={data.wpPage.title}/>
          <IntroSectionReuseable defaultPageFields={data.wpPage.defaultPageFields} />
          <BrandsGrid brands={brands} handleActiveBrand={handleActiveBrand} />
          <CTASection ctaType={'chat'} />
        </div>
        <BrandsOffscreen toggleOffPageHide={toggleOffPageHide} activeBrand={{brand:activeBrand}} />
      </div>
  )
}

export const query = graphql`
  query {
    brands: allWpBrand(sort: {fields: title, order: ASC}) {
      nodes {
        title
        brandsFields {
          description
          website
          mainImageFocalPoint
          mainImage{
            localFile{
              absolutePath
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }

          logo {
            localFile{
              absolutePath
              childImageSharp {
                gatsbyImageData(
                  width: 2000
                  placeholder: NONE
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
    wpPage(title: {eq: "Our brands"}) {
      id
      title
      seo {
        title
        metaDesc
      }
      defaultPageFields {
        contentTitle
        contentText
        contentImage {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 2000
                placeholder: NONE
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
      customHeroImage {
          heroImage {
              localFile{
                publicURL
                childImageSharp {
                    gatsbyImageData(
                      width: 1500
                      placeholder: NONE
                      formats: [AUTO, WEBP]
                    )
                }
              }
          }
      }
    }
  }
`